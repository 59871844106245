// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


/* -------------------------------------------
Styles reutilisables
---------------------------------------------*/
@font-face {
    font-family: rondalo;
    src: url('/fonts/rondalo.ttf') format('truetype');
}

* {
    font-weight: normal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
}

/* 545557 */
body {
    background-color: #fff;
    font-family: rondalo, sans-serif;
    font-size: 18px;
    color: #787878;
}

h1 {
    font-weight: 400;
    font-size: 50px;
    letter-spacing: 2px;
    color: #8dbb10;
    position: relative;
}

h2 {
    font-weight: normal;
    font-size: 90px;
    letter-spacing: 2px;
    color: #8dbb10;
    position: relative;
    text-transform: uppercase;
}

header {
    text-align: center;
    margin-top: 40px;
}

img {
    height: auto;
    max-width: 100%;
}

.font-size {
    font-size: 15px;
}

.picture-presente-1 {
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;
}

.picture-presente-2 {
    position: absolute;
    top: 0;
    left: 0;
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;
}

.picture-presente-3 {
    position: absolute;
    top: -35px;
    left: -30px;
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;
}

.separation {
    margin-bottom: 80px;
}

.header {
    margin-top: 60px;
}

.presente span {
    color: #8dbb10;
    font-weight: 700;
}

a, a:hover, a:active {
    color: #8dbb10;
    text-decoration: none;
}

.menu {
    margin-bottom: 60px;
    position: relative;
}

.menu-150 {
    margin-bottom: 150px;
}

.explain {
    margin-bottom: 40px;
    font-size: 19px;
    line-height: 20px;
    background: #eaeaea;
    padding: 10px 6px 10px 9px;
    border: 5px solid #fff;
    -webkit-border-radius: 11px;
    -moz-border-radius: 11px;
    border-radius: 11px;
}

.menu img {
    float: left;
    margin-top: 8px;
    -webkit-border-radius: 80px;
    -moz-border-radius: 80px;
    border-radius: 80px;
    -webkit-box-shadow: 0 0 0 5px #f1f1f1;
    -moz-box-shadow: 0 0 0 5px #f1f1f1;
    box-shadow: 0 0 0 5px #f1f1f1;
    width: 150px;
    height: 150px;
}

.menu .menu-content {
    margin-left: 110px;
    padding-top: 25px;
}

.menu .menu-content-150 {
    margin-left: 170px;
    padding-top: 25px;
    @media(max-width: 768px){
        margin-left: 0;
        width: 100%;
    }
}

.menu .menu-content .dotted, .menu .menu-content-150 .dotted {
    background: url(../img/dotted.png) repeat-x 0 68%;
    position: relative;
    display: block;
    @media(max-width: 768px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: none;
        .price {
            position: relative;
        }
    }
}

.menu .menu-content .h3, .menu .menu-content-150 .h3 {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    background: #fff;
    padding-right: 3px;
    display: inline;
}

.menu .menu-content .price,
.menu .menu-content-150 .price {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    background: #eaeaea;
    padding: 4px 6px 3px 9px;
    display: inline;
    position: absolute;
    bottom: -4px;
    right: 0;
    border: 5px solid #fff;
    -webkit-border-radius: 11px;
    -moz-border-radius: 11px;
    border-radius: 11px;
}

.menu .menu-content .price + .price,
.menu .menu-content-150 .price + .price{
    margin: -35px 0;
    @media (max-width: 768px) {
        margin: 0;
    }
}

.menu .menu-content .description,
.menu .menu-content-150 .description {
    padding: 0;
    margin-top: 10px;
}

.menu .menu-content .description p,
.menu .menu-content-150 .description p {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 32px;
}

.new {
    background-color: #8dbb10;
    color: #fff;
    font-weight: bold;
    padding: 2px 5px;
}

.staff {
    margin-bottom: 10px;
    position: relative;
}

.staff img {
    border: 10px solid #f1f1f1;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    border-radius: 999px;
}

.staff .staff-content h3 {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
    background: #fff;
    padding-right: 3px;
    display: inline;
}

.staff .staff-content span {
    font-size: 28px;
    letter-spacing: 2px;
    color: #8dbb10;
}

.staff .staff-content .description {
    padding: 10px 0 0 0;
}

.staff .staff-content .description p {
    font-size: 16px;
    line-height: 22px;
}

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}

.media {
    padding-left: 50px;
}

.instagram-list {
    .instagram-item, .instagram-image {
        height: 200px;
        width: 200px;
        background-size: cover;
    }
}

footer {
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .picture-presente-3 {
        width: 115px;
    }
}

@media (max-width: 980px) {
    h1 {
        font-size: 40px;
    }
    h2 {
        font-size: 30px;
    }
}
